import { class_type } from "./fable_modules/fable-library.3.7.17/Reflection.js";
import { createElement } from "react";
import * as react from "react";
import { tail, head, isEmpty, ofArray } from "./fable_modules/fable-library.3.7.17/List.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.66.0/./Interop.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.66.0/React.fs.js";
import { createObj } from "./fable_modules/fable-library.3.7.17/Util.js";
import { RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { singleton, delay, toList } from "./fable_modules/fable-library.3.7.17/Seq.js";

export class Components {
    constructor() {
    }
}

export function Components$reflection() {
    return class_type("App.Components", void 0, Components);
}

export function Components_HelloWorld() {
    const children = ofArray(["Våre nye sider kommer her. Inntil videre finner du \n            informasjon om våre aktiviteter i ", createElement("a", {
        className: "link link-primary",
        href: "https://www.facebook.com/groups/hoafno",
        children: "Facebook-gruppen",
    }), " vår."]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Components_Counter() {
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const setCount = patternInput[1];
    const count = patternInput[0] | 0;
    const children = ofArray([createElement("h1", {
        children: [count],
    }), createElement("button", {
        onClick: (_arg) => {
            setCount(count + 1);
        },
        children: "Increment",
    }), createElement("button", {
        onClick: (_arg_1) => {
            setCount(count - 1);
        },
        children: "Decrement",
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Components_Header() {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["className", "hero"], (elems_1 = [createElement("div", createObj(ofArray([["className", "hero-content"], (elems = [createElement("img", {
        src: "img/cropped-hoaftop.jpg",
        alt: "Hamar og Omegn Astronomiforening",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function Components_Footer() {
    let elems_3, elems, elems_2, elems_1;
    return createElement("div", createObj(ofArray([["className", "footer footer-center items-center p-10"], (elems_3 = [createElement("div", createObj(ofArray([["className", "items-center grid-flow-col"], (elems = [createElement("p", {
        children: ["2022"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "grid-flow-col gap-4 md:place-self-center"], (elems_2 = [createElement("a", createObj(ofArray([["href", "https://www.facebook.com/groups/hoafno"], (elems_1 = [createElement("img", {
        className: "invert",
        src: "img/facebook.svg",
        alt: "Facebook logo",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function Components_MainPage_Z45A274A0(components_MainPage_Z45A274A0InputProps) {
    const content = components_MainPage_Z45A274A0InputProps.content;
    const children = ofArray([components_MainPage_Z45A274A0InputProps.header, createElement("div", {
        className: "grid grid-cols-1 gap-4 mx-5",
        children: Interop_reactApi.Children.toArray([content]),
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Components_Router() {
    let elements;
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const currentUrl = patternInput[0];
    return RouterModule_router(createObj(ofArray([["onUrlChanged", patternInput[1]], (elements = toList(delay(() => ((!isEmpty(currentUrl)) ? ((head(currentUrl) === "hello") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(Components_HelloWorld, null)) : singleton(createElement("h1", {
        children: ["Not found"],
    }))) : ((head(currentUrl) === "counter") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(Components_Counter, null)) : singleton(createElement("h1", {
        children: ["Not found"],
    }))) : singleton(createElement("h1", {
        children: ["Not found"],
    })))) : singleton(createElement(Components_MainPage_Z45A274A0, {
        header: createElement(Components_Header, null),
        content: createElement(Components_HelloWorld, null),
    }))))), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

